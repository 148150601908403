<template>
  <div
    class="group data-[expandable=true]:h-[2lh] data-[expanded=true]:overflow-visible data-[expanded=true]:z-20 relative outline-none"
    :data-expanded="expanded"
    :tabindex="expandable ? 0 : -1"
    :data-expandable="expandable"
    @click="toggleExpanded"
    @keydown.space="toggleExpanded"
  >
    <div
      class="rounded border-2 data-[status='INFO']:border-supporting-45 data-[status='INFO']:bg-supporting-49 data-[status='WARNING']:border-supporting-25 data-[status='WARNING']:bg-supporting-28 data-[status='ERROR']:border-supporting-14 data-[status='ERROR']:bg-supporting-19 data-[status='SUCCESS']:border-supporting-35 data-[status='SUCCESS']:bg-supporting-39 data-[status='HIGHLIGHT']:border-supporting-25 data-[status='HIGHLIGHT']:bg-supporting-28 p-2 contrast:bg-c-secondary-0 contrast:border-base-1 data-[expandable=true]:cursor-pointer group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-offset-1 outline-primary-1"
      :data-expandable="isEllipsisActive && expandable"
      :data-expanded="expanded"
      :data-status="status"
    >
      <span
        ref="truncatedTextElement"
        class="line-clamp-1 data-[expanded=true]:line-clamp-none data-[expandable=false]:line-clamp-none data-[text-align='left']:text-left data-[text-align='center']:text-center data-[text-align='right']:text-right data-[text-align='justify']:text-justify"
        :data-expanded="expanded"
        :data-expandable="expandable"
        :data-text-align="textAlign"
      >
        <IconPzo
          v-if="withIcon && status === 'INFO'"
          name="info-no-circle"
          class="mr-2 rounded-full text-supporting-40 float-left border-2 border-supporting-42 bg-base-2 w-[0.9lh] h-[0.9lh]"
        ></IconPzo>
        <IconPzo
          v-if="withIcon && status === 'WARNING'"
          name="exclamation"
          class="mr-2 rounded-full text-supporting-22 float-left border-2 border-supporting-22 bg-base-2 w-[0.9lh] h-[0.9lh]"
        ></IconPzo>
        <IconPzo
          v-if="withIcon && status === 'ERROR'"
          name="close"
          class="mr-2 rounded-full text-supporting-12 float-left border-2 border-supporting-13 bg-base-2 w-[0.9lh] h-[0.9lh]"
        ></IconPzo>
        <IconPzo
          v-if="withIcon && status === 'HIGHLIGHT'"
          name="info-no-circle"
          class="mr-2 rounded-full text-supporting-22 float-left border-2 border-supporting-22 bg-base-2 w-[0.9lh] h-[0.9lh]"
        ></IconPzo>
        <IconPzo
          v-if="withIcon && status === 'SUCCESS'"
          name="done"
          class="mr-2 rounded-full text-supporting-32 float-left border-2 border-supporting-33 bg-base-2 w-[0.9lh] h-[0.9lh]"
        ></IconPzo>
        <slot></slot>
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
interface Props {
  expandable?: boolean;
  withIcon?: boolean;
  status: "INFO" | "WARNING" | "ERROR" | "HIGHLIGHT" | "SUCCESS";
  textAlign?: "left" | "center" | "right" | "justify";
}

const props = withDefaults(defineProps<Props>(), {
  expandable: false,
  withIcon: true,
  textAlign: "justify",
});

const expanded = ref<boolean>(false);
const truncatedTextElement = ref<HTMLElement>();
const isEllipsisActive = ref<boolean>(false);
const targetIsVisible = useElementVisibility(truncatedTextElement);

watch(
  targetIsVisible,
  () => {
    expanded.value = false;
    if (truncatedTextElement.value === undefined) {
      isEllipsisActive.value = false;
      return;
    }
    isEllipsisActive.value =
      truncatedTextElement.value.offsetHeight <
      truncatedTextElement.value.scrollHeight;
  },
  {
    immediate: true,
  },
);

function toggleExpanded() {
  if (props.expandable && isEllipsisActive.value) {
    expanded.value = !expanded.value;
  }
}
</script>
